/**
 * Import Globals
 */
import * as globals from './utils/globals'
import {formFields} from './utils/auto-init/formFields'

/**
 * Auto Inits
 */
import {autoInitConstants} from './utils/auto-init/index'

import ClassFactory from "./class-factory";

const classFactory = new ClassFactory()

let jsWrapper = document.getElementById('js-ajax-wrapper')
let pageType = jsWrapper.getAttribute('data-namespace')

classFactory.getPageInstance(jsWrapper, pageType)

formFields()