import DefaultBlock from ".//default-block"

import * as Utils from "./../utils/utils"

export default class Video extends DefaultBlock {
  init () {
    super.init()

    this.button = this.cont.getElementsByClassName('video__button')[0]
    this.overlay = document.getElementsByClassName('video-overlay')[0]
    this.overlayContainer = document.getElementsByClassName('video-overlay__container')[0]

    this.openOverlayBound = this.openOverlay.bind(this)
    this.closeOverlayBound = this.closeOverlay.bind(this)
  }

  initEvents () {
    super.initEvents()

    this.button.addEventListener('click', this.openOverlayBound)
    this.overlay.addEventListener('click', this.closeOverlayBound)
    this.overlayContainer.addEventListener('click', this.stopPropagation)
  }

  destroyEvents () {
    super.destroyEvents()

    this.button.removeEventListener('click', this.openOverlayBound)
    this.overlay.removeEventListener('click', this.closeOverlayBound)
    this.overlayContainer.removeEventListener('click', this.stopPropagation)
  }

  openOverlay () {
    Utils.setAttribute(this.overlay, 'visible', true)
  }

  closeOverlay () {
    Utils.setAttribute(this.overlay, 'visible', false)
  }

  stopPropagation (e) {
    e.stopPropagation()
  }
}
