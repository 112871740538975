import DefaultPage from "./pages/default-page"
import HomePage from "./pages/home-page"

import DefaultBlock from "./blocks/default-block"
import Video from "./blocks/video"

export default class ClassFactory {
  getPageInstance(page, pageType) {

    switch(pageType) {
      case 'home':
        return new HomePage(page, pageType)
      default:
        console.log('"' + pageType + '" has no defined route, using DefaultPage.')
        return new DefaultPage(page, pageType)
    }
  }

  getBlockInstance(block, blockType) {

    switch(blockType) {
      case 'video':
        return new Video(block, blockType)
      default:
        console.log('"' + blockType + '" has no defined class, using DefaultBlock.')
        return new DefaultBlock(block, blockType)
    }
  }
}