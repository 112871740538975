import DefaultPage from "./default-page"

export default class HomePage extends DefaultPage {
  init () {
    super.init()

    this.initEvents()
  }

  initEvents () {
    super.initEvents()
  }

  destroyEvents () {
    super.destroyEvents()
  }
}
